<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #keyPoints>
            <ul>
                <li>
                    7th best school for research in 2021
                </li>
                <li>
                   8th best school for pedagogy in 2021
                </li>
                <li>
                    2nd best school for social policy in 2021
                </li>
                <li>
                    1st school for apprenticeship and work-study programmes in 2021
                </li>
            </ul>
        </template>
        <template #description>  
            Montpellier BS была основана еще в ​​1897 году Торгово-промышленной палатой Монпелье. 
            <br>
            Команда Student Experience Montpellier BS ежегодно опрашивает студентов, чтобы предложить проекты, которые лучше соответствуют их потребностям. Так появилось новое мобильное приложение MY MBS 2.0, позволяющее сосредоточить всю полезную информацию и инструменты для студенческой жизни и учебы в одном месте: расписание, пропуски, оценки, предложения стажировки и работы-учебы, каталоги и т. д. Эта новая функция также обеспечивает общение со студентами в режиме реального времени с помощью push-уведомлений.
            <br>
            Независимо от того, откуда вы приехали, в MBS вы будете чувствовать себя как дома.
            Школа привлекает студентов и преподавателей со всего мира. Это дает возможность работать, обмениваться и устанавливать связи - в школе учатся более чем 1000 иностранных студентов 80 разных национальностей.
            Монпелье на протяжении веков был студенческим городом. Более 18% его населения составляют студенты, из них 1 из 7 - иностранный студент.
            Все дневные программы MBS преподаются на французском или английском языках, в зависимости от выбора студента. Более 60% из 100 преподавателей - профессора из 45 стран мира.
            MBS имеет более 19 000 выпускников в более чем 90 странах.
        </template>
        
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/embed/tPLUmNqHD8c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #speciality>
            Finance, business, economy
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="baa">Bachelor Programme</option>
                                <option value="mim">Master in Management (MIM)</option>
                                <option value="msc">Master of Science</option>
                            </select>
                        </div>
                    </div>
                    
                    <Program :url="'/mbs/Montpellier_BAA_english.pdf'" v-if="this.program === 'baa'" id="baa">
                        <template #title>
                            Бакалавриат - Bachelor Programme
                        </template>
                        <template #description>
                            <p>
                            YEAR 1 * ■ Marketing ■ Business ethics ■ Accounting ■ Management and organisations ■ Quantitative techniques applied to management ■ Legal business environment ■ Cross cultural management ■ Leadership ■ Geopolitics
                            </p>
                            <p>
                            YEAR 2 * ■ Responsible management ■ Responsible marketing mix ■ Design thinking ■ Project management ■ Human resources and talent management ■ International business ■ Financial analysis ■ Sales negotiations ■ Globalisation ■ Microeconomics ■ Statistics applied to management ■ Analytical accounting
                            </p>
                            <p>
                            YEAR 3 * ■ International marketing ■ Digital business ■ Digital marketing ■ French luxury & excellence ■ International economy ■ Corporate finance ■ Strategy ■ Information systems management ■ Managing differently ■ Corporate reputation ■ International financial markets ■ Supply chain ■ International entrepreneurship
                            </p>
                        </template>
                        <template #duree>
                            4 года
                        </template>
                        <template #language>
                            французский или английский
                        </template>
                        <template #cost>
                             9.000 евро/год (на французском), 11.000 евро/год (на английском)
                        </template>
                        <template #requirements>
                            английский или французский язык уровня B2, досье, интервью.
                        </template>
                        <template #campus>
                            Монтпелье
                        </template>
                        <template #start-time>
                            сентябрь 
                        </template>
                    </Program>

                    <Program :url="'/mbs/Montpellier_MIM_english.pdf'" v-if="this.program === 'mim'" id="mim">
                        <template #title>
                            Master in Management (MIM) - Grande Ecole Programme
                        </template>
                        <template #duree>
                            2 года
                        </template>
                        <template #language>
                            французский или английский
                        </template>
                        <template #cost>
                            12.500-13.000 евро/год
                        </template>
                        <template #requirements>
                            французский язык B2, досье, интервью.
                        </template>
                        <template #campus>
                            Монтпелье
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #salary>
                            38.000-42.000 евро/год
                        </template>
                    </Program>

                    <Program :url="'/mbs/Montpellier_MSc.pdf'" v-if="this.program === 'msc'" id="msc">
                        <template #title>Master of Science</template>
                        <template #duree>
                            1 года 
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            15.500 евро/год 
                        </template>
                        <template #requirements>
                             английский язык B2, досье, интервью
                        </template>
                        <template #campus>
                            сентябрь
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #salary>
                            44.000 евро/год
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>