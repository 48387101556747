<template>
    <Introduction :url="'/brochure/mbs'" :class="'introduction-mbs'">  
        <template #title>
            Montpellier Business School
        </template>
        <template #speciality>
            Бизнес
        </template>
        <template #min-duration>
            От 2 лет
        </template>
        <template #cost>
            От 9000 €/год
        </template>
        <template #campus>
            Монпелье
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>